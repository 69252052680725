import React from "react";
import { Tag, Divider } from "antd";
import { CONTACT_CHECK_FLAGS } from "eurst-shared/src/enums";

function UserContactCheckFlags({ contactProfile }) {
  if (!contactProfile) {
    return null;
  }

  return (
    <center>
      {CONTACT_CHECK_FLAGS.map((flag) => {
        const color = contactProfile.attributes[flag.id] ? "green" : "red";

        return (
          <Tag key={flag.id} color={color}>
            {flag.label}
          </Tag>
        );
      })}
      <Divider />
    </center>
  );
}

export default UserContactCheckFlags;
