import React from "react";
import { Form, Input } from "antd";
import { USER_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function UserName({ user, disabled }) {
  if (user.type === USER_TYPES_AS_OBJECT.natural_person) {
    return (
      <React.Fragment>
        <Form.Item
          name="firstName"
          label="First Name"
          hasFeedback
          rules={[
            {
              required: true,
              message: "First name is required!",
            },
            {
              max: 80,
              message: "First name should be less than 80 symbols!",
              whitespace: true,
            },
          ]}>
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Last Name is required!",
            },
            {
              max: 80,
              message: "Last Name should be less than 80 symbols!",
              whitespace: true,
            },
          ]}>
          <Input disabled={disabled} />
        </Form.Item>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Form.Item
        name="userName"
        label="Name"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Name is required!",
          },
          {
            max: 80,
            message: "Name should be less than 80 symbols!",
            whitespace: true,
          },
        ]}>
        <Input disabled={disabled} />
      </Form.Item>
    </React.Fragment>
  );
}

export default UserName;
