/* eslint-disable */
import React, { useEffect, useState } from "react";
import { getUsersFilterable } from "../../services/users";
import { shortener } from "eurst-shared/src/helpers/string";
import { USER_ROLES } from "eurst-shared/src/enums";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import { Table, Row } from "antd";
import moment from "moment";
import { FORMAT_FOR_DATE_WITH_TIME } from "../../constants/constants";
import ReloadButton from "../../components/ReloadButton";
import UserFilter from "./user-filter";
import UserActions from "./actions";
import { UserStatus, UserType, UserAlerts } from "../../components/UserEditor";

import EventEmitter from "../../services/event-emitter";
import events from "./events";
import { CopyableLink, CopyableText } from "../../components/Copyable";
import { etherscanUrl } from "eurst-shared/src/helpers/urls";

const network = process.env.REACT_APP_NETWORK;

function TableUserList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const initialFilter = {};

  const [filter, setFilter] = useState(initialFilter);

  const initialPagination = {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    position: ["topRight", "bottomRight"],
    //hideOnSinglePage: true,
    onChange: (page) => {
      pagination.current = page;

      setPagination(pagination);
    },
    onShowSizeChange: (current, size) => {
      pagination.pageSize = size;

      setPagination(pagination);
    },
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      /* eslint-disable react/display-name*/
      render: (value) => {
        return moment(value).format(FORMAT_FOR_DATE_WITH_TIME);
      },
      /* eslint-enable react/display-name*/
    },
    {
      /* eslint-disable react/display-name*/
      title: () => (
        <div>
          Username/
          <br />
          First Name
          <br />
          Last name
        </div>
      ),
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => {
        const result = record.firstName
          ? `${record.firstName} ${record.lastName}`
          : record.userName;
        return result;
      },
      /* eslint-enable react/display-name*/
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      /* eslint-disable react/display-name*/
      render: (value) => {
        if (!value) return "n/a";

        return (
          <React.Fragment>
            <CopyableText label={value} value={value} />
          </React.Fragment>
        );
      },
      /* eslint-enable react/display-name*/
    },
    {
      title: "Wallet",
      dataIndex: "ethAddress",
      key: "ethAddress",
      /* eslint-disable react/display-name*/
      render: (value) => {
        if (!value) return "n/a";

        return (
          <React.Fragment>
            <CopyableLink
              label={shortener(value)}
              value={value}
              url={etherscanUrl(network, "address", value)}
            />
          </React.Fragment>
        );
      },
      /* eslint-enable react/display-name*/
    },
    // {
    //   title: "User Name",
    //   dataIndex: "userName",
    //   key: "userName",
    //   responsive: ["xxl"],
    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      /* eslint-disable react/display-name*/
      render: (value, record) => <UserType user={record} />,
      /* eslint-enable react/display-name*/
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      /* eslint-disable react/display-name*/
      render: (value, record) => {
        return (
          <React.Fragment>
            <UserStatus user={record} />
            <UserAlerts data={record.kycRequiredActions} header={"KYC required actions"} />
            <UserAlerts
              data={record.kycDocumentCheckResults}
              header={"KYC document check reasons"}
              showStatus={true}
            />
          </React.Fragment>
        );
      },
      /* eslint-enable react/display-name*/
    },
    {
      title: "Blocked",
      dataIndex: "disabled",
      key: "disabled",
      /* eslint-disable react/display-name*/
      render: (value) => {
        return value ? (
          <ExclamationCircleOutlined style={{ color: "red" }} title="Blocked" />
        ) : (
          <CheckCircleOutlined style={{ color: "green" }} />
        );
      },
      /* eslint-enable react/display-name*/
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (value) => {
        const result = USER_ROLES[value].label;
        return result;
      },
    },
    {
      title: "Action",
      key: "action",
      /* eslint-disable react/display-name*/
      render: (text, record) => {
        return <UserActions filter={filter} requestedUser={record} />;
      },
      /* eslint-enable react/display-name*/
    },
  ];

  const fetchData = async (filters, pagination) => {
    setLoading(true);

    try {
      const response = await getUsersFilterable(filters, pagination);

      setData(response.data);

      pagination.total = response.count;

      setPagination(pagination);
    } catch (err) {
      console.error("ERRR", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(initialFilter, pagination);

    const deleteEventId = EventEmitter.subscribe(events.deleteUser, handleTableChange);
    const updateRoleEventId = EventEmitter.subscribe(events.userUpdated, handleTableChange);

    return function cleanup() {
      EventEmitter.unsubscribe(events.deleteUser, deleteEventId);
      EventEmitter.unsubscribe(events.userUpdated, updateRoleEventId);
    };
  }, []);

  const handleTableChange = (updatedFilter) => {
    if (updatedFilter && !updatedFilter.current) {
      // can be if filter data - then click to Page - can be pagination object
      setFilter(updatedFilter);
      pagination.current = 1;
      return fetchData(updatedFilter, pagination);
    }

    return fetchData(filter, pagination);
  };

  return (
    <React.Fragment>
      <Row style={{ float: "right" }}>
        <UserFilter onSubmit={handleTableChange} onReset={handleTableChange} loading={loading} />

        <ReloadButton disabled={loading} onClick={() => handleTableChange(filter)} />
      </Row>
      <Table
        key="users"
        rowKey={(record) => record.id}
        pagination={pagination}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        columns={columns}
      />
    </React.Fragment>
  );
}

export default TableUserList;
