import React from "react";
import { Typography } from "antd";
import { etherscanUrl } from "eurst-shared/src/helpers/urls";
import "./index.css";

function Title({ minter, network }) {
  const url = etherscanUrl(network, "address", minter.address);
  return (
    <Typography.Link target="_blank" href={url}>
      Minter balance
    </Typography.Link>
  );
}

function MinterAlarm({ minter, network }) {
  return (
    <span className={"minter-alarm"}>
      <span>
        <Title minter={minter} network={network} />
      </span>
      :&nbsp;
      <span>{`${minter.balance} ${minter.unit}`}</span>
    </span>
  );
}

export default MinterAlarm;
