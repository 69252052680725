import Cookies from "js-cookie";
import { getInstance } from "../utils/helpers";

async function getMinter() {
  const url = `/api/eurst/minter`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  const result = await getInstance().get(url);

  return result;
}

export { getMinter };
