import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = Cookies.get("token");
  if (!token) return <Redirect to="/" />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <div style={{ marginRight: 10 }}>
          <Component {...props} />
        </div>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
