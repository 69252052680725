import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Space, Dropdown, Menu, Popconfirm } from "antd";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import { Context } from "../../store";
import { USER_ROLES_AS_OBJECT } from "eurst-shared/src/enums";
import { deleteUser, toggleUser } from "../../services/admin";
import { updateUserRole } from "../../services/users";
//import UserEditor from "./editor";
import EventEmitter from "../../services/event-emitter";
import events from "./events";

const label = "UserActions";

function UserActions({ requestedUser, filter }) {
  const [loading, setLoading] = useState(false);

  const [state] = useContext(Context);

  const cancel = () => {
    return;
  };

  const handleDeleteUser = async (requestedUserId) => {
    setLoading(true);

    try {
      const response = await deleteUser(requestedUserId);
      if (response.status === 200) {
        toast("User is successfully deleted.", {
          type: "success",
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.error(`${label}.handleDeleteUser`, error);
    } finally {
      EventEmitter.dispatch(events.deleteUser, filter);
      setLoading(false);
    }
  };

  const handleUpdateToggleDisabled = async (requestedUserId, disabled) => {
    setLoading(true);

    try {
      const response = await toggleUser(requestedUserId, disabled);
      if (response.status === 200) {
        toast("User is successfully updated.", {
          type: "success",
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.error(`${label}.handleUpdateToggleDisabled`, error);
    } finally {
      EventEmitter.dispatch(events.userUpdated, filter);
      setLoading(false);
    }
  };

  const handleUpdateUserRole = async (requestedUserId, role) => {
    setLoading(true);

    try {
      const response = await updateUserRole(requestedUserId, { role });
      if (response.status === 200) {
        const {
          data: { role },
        } = response;
        if (role === USER_ROLES_AS_OBJECT.root) {
          toast("The role was successfully added to the user.", {
            type: "success",
            position: "bottom-right",
          });
        } else {
          toast("The role was successfully removed from the user.", {
            type: "success",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      console.error(`${label}.handleUpdateUserRole`, error);
    } finally {
      EventEmitter.dispatch(events.userUpdated, filter);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Space size="middle">
        {state.user.email !== requestedUser.email && (
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item>
                  <Link to={`/main/users/${requestedUser.id}`}>User details</Link>
                  {/*<UserEditor filter={filter} requestedUser={requestedUser} />*/}
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleUpdateUserRole(
                      requestedUser.id,
                      requestedUser.role !== USER_ROLES_AS_OBJECT.root
                        ? USER_ROLES_AS_OBJECT.root
                        : USER_ROLES_AS_OBJECT.user
                    )
                  }>
                  {requestedUser.role !== USER_ROLES_AS_OBJECT.root
                    ? "Add Admin Role"
                    : "Remove Admin Role"}
                </Menu.Item>
                <Menu.Item
                  onClick={
                    () =>
                      /*eslint-disable  no-extra-boolean-cast*/
                      handleUpdateToggleDisabled(requestedUser.id, !!!requestedUser.disabled)
                    /*eslint-enable  no-extra-boolean-cast*/
                  }>
                  {requestedUser.disabled ? "Enable User" : "Disable User"}
                </Menu.Item>
                <Menu.Item>
                  <Popconfirm
                    title="Are you sure to delete this user?"
                    onConfirm={() => handleDeleteUser(requestedUser.id)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No">
                    <a href="#" disabled={loading}>
                      Delete
                    </a>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            )}>
            <a className="ant-dropdown-link">
              <MenuOutlined />
            </a>
          </Dropdown>
        )}
      </Space>
    </React.Fragment>
  );
}

export default UserActions;

// <React.Fragment>
//           <Space size="middle">
//             {state.user.email !== record.email && (
//               <Dropdown
//                 overlay={() => (
//                   <Menu>
//                     <Menu.Item>
//                       <Popconfirm
//                         title="Are you sure to delete this user?"
//                         onConfirm={() => handleDeleteUser(record.id)}
//                         onCancel={cancel}
//                         okText="Yes"
//                         cancelText="No">
//                         <a href="#">Delete</a>
//                       </Popconfirm>
//                     </Menu.Item>
//                     <Menu.Item
//                       onClick={() => handleUpdateUserStatus(record.id, !!!record.disabled)}>
//                       {record.disabled ? "Enable User" : "Disable User"}
//                     </Menu.Item>
//                     <Menu.Item onClick={() => handleShowKYCInformation(record)}>
//                       {"Show KYC information"}
//                     </Menu.Item>
//                     <Menu.Item
//                       onClick={() =>
//                         handleUpdateUserRole(
//                           record.id,
//                           record.role !== USER_ROLES_AS_OBJECT.root
//                             ? USER_ROLES_AS_OBJECT.root
//                             : USER_ROLES_AS_OBJECT.user
//                         )
//                       }>
//                       {record.role !== USER_ROLES_AS_OBJECT.root
//                         ? "Add Admin Role"
//                         : "Remove Admin Role"}
//                     </Menu.Item>
//                   </Menu>
//                 )}>
//                 <a className="ant-dropdown-link">
//                   <EllipsisOutlined rotate={90} />
//                 </a>
//               </Dropdown>
//             )}
//           </Space>
//         </React.Fragment>
