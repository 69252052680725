/* eslint-disable */
import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import Reducer from "../reducer";

const initialState = {
  currentNav: "dashboard",
  user: {},
  amountUSD: 0,
  amountEURST: 0,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);

Store.propTypes = {
  children: PropTypes.element,
};

Store.defaultProps = {
  children: null,
};
export default Store;
