/* eslint-disable */
import { getInstance } from "../utils/helpers";
import { USER_ROLES_AS_OBJECT } from "eurst-shared/src/enums";
import Cookies from "js-cookie";

function registration({ userName, email, password, countryCode }) {
  const payload = {
    userName,
    email,
    password,
    countryCode,
  };
  const url = "/api/auth/register";
  return getInstance().post(url, payload);
}

function login({ email, password }) {
  const payload = {
    email,
    password,
  };
  const url = "/api/auth/protected";
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getInstance().post(url, payload);

      if (response.data.role !== USER_ROLES_AS_OBJECT.root) {
        reject({
          data: {
            message: "User is not admin approved to access this app.",
            code: "no-permissions",
          },
        });
      } else if (response.data.disabled) {
        reject({
          data: {
            message: "User is not approved to access this app.",
            code: "user-disabled",
          },
        });
      } else {
        resolve(response);
      }
    } catch (e) {
      console.log(e?.data?.message);
    }
  });
}

function logout(socket) {
  Cookies.remove("token");
  if (socket) {
    Cookies.remove("channelToken");
    //socket.emit("logout");
  }
}

function verification(token) {
  const url = "/api/auth/register-confirm";
  const payload = {
    token,
  };
  return getInstance().put(url, payload);
}

export default {
  login,
  registration,
  verification,
  logout,
};
