import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import Spin from "antd/lib/spin";

import PrivateRoute from "./components/PrivateRoute";
import { useSuperState } from "./hooks";
import LoginPage from "./pages/LoginPage";
import MainTemplate from "./pages/MainTemplate";
import { getUser } from "./services/users";
import { Context } from "./store";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const context = useContext(Context);
  const dispatch = context[1];
  const [readyForRender, setReadyForRender] = useSuperState(false, true);

  useEffect(() => {
    (async () => {
      const token = Cookies.get("token");
      if (token) {
        try {
          const { data } = await getUser();
          dispatch({ type: "SET_USER", payload: data });
        } catch (e) {
          console.error(e.data);
        } finally {
          setReadyForRender(true);
        }
      } else {
        setReadyForRender(true);
      }
    })();
  }, [dispatch, setReadyForRender]);

  if (!readyForRender) return <Spin size="large" className="ant-spinner" />;

  return (
    <>
      <div className="App">
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <PrivateRoute path="/main" component={MainTemplate} />
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default App;
