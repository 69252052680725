import React from "react";
import PropTypes from "prop-types";
import Table from "antd/lib/table";
import "./index.css";

const AwsLogsTable = ({ logEvents, loading }) => {
  const columns = [
    {
      align: "center",
      dataIndex: "timestamp",
      key: "timestamp",
      ellipsis: true,
      title: "Timestamp",
      width: "180px",
    },
    {
      align: "left",
      dataIndex: "message",
      key: "message",
      ellipsis: true,
      responsive: ["xxl", "xl", "lg", "sm"],
      title: "Message",
      render: (value, record) => {
        const dateRegExp = new RegExp(
          /^[\d]{4}-[\d]{2}-[\d]{2}\s[\d]{2}:[\d]{2}\s.[\d]{2}:[\d]{2}:\s/
        );
        const text = record.message.replace(dateRegExp, "");

        try {
          const jsonText = JSON.parse(text);
          return (
            <pre style={{ margin: 0, wordWrap: "break-word", whiteSpace: "break-spaces" }}>
              {JSON.stringify(jsonText, null, 4)}
            </pre>
          );
        } catch {
          return <p>{text}</p>;
        }
      },
    },
  ];

  // const onExpend = (log) => {
  //   const dateRegExp = new RegExp(/^[\d]{4}-[\d]{2}-[\d]{2}\s[\d]{2}:[\d]{2}\s.[\d]{2}:[\d]{2}:\s/);
  //   const text = log.message.replace(dateRegExp, "");
  //
  //   try {
  //     const jsonText = JSON.parse(text);
  //     return <pre style={{ margin: 0 }}>{JSON.stringify(jsonText, null, 4)}</pre>;
  //   } catch {
  //     return <p>{text}</p>;
  //   }
  // };

  return (
    <Table
      className={"title-row-hidden"}
      columns={columns}
      dataSource={logEvents}
      pagination={{
        defaultPageSize: 100,
      }}
      expandable={{
        // expandedRowRender: onExpend,
        // expandRowByClick: true,
        defaultExpandAllRows: true,
      }}
      loading={loading}
    />
  );
};

export default AwsLogsTable;

AwsLogsTable.propTypes = {
  loading: PropTypes.bool,
  logEvents: PropTypes.arrayOf(PropTypes.object),
};
