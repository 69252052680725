import {
  CloudWatchLogsClient,
  DescribeLogGroupsCommand,
  DescribeLogStreamsCommand,
  FilterLogEventsCommand,
  GetLogEventsCommand,
} from "@aws-sdk/client-cloudwatch-logs";
import { formatDate } from "eurst-shared/src/helpers/date";
import { getInstance } from "../utils/helpers";

const CREDENTIAL_URL = "/api/log-token";

export default class AwsCloudWatchService {
  constructor() {
    this.DEFAULT_LOG_LIMIT = 10000;
    this.client = null;
    this.sessionEndTimestamp = null;
  }

  /**
   * Lists the specified log groups. You can list all your log groups or filter the results by prefix. The results are ASCII-sorted by log group name.
   * @param {object} [query={}]
   * @param {number} [query.limit] - The maximum number of items returned. If you don't specify a value, the default is up to 50 items.
   * @param {string} [query.logGroupNamePrefix] - The prefix to match.
   * @param {string} [query.nextToken] - The token for the next set of items to return. (You received this token from a previous call.)
   * @returns {Promise<string[]>} - Lists the specified log groups.
   */
  async getLogGroups(query = {}) {
    try {
      await this.checkSessionExpiration();

      const command = new DescribeLogGroupsCommand({
        logGroupNamePrefix: "wallex",
        ...query,
      });
      const { logGroups } = await this.client.send(command);

      return logGroups.map((item) => item.logGroupName);
    } catch (e) {
      throw new Error(`Some problems with log groups!\n ${e}`);
    }
  }

  /**
   * Lists the log streams for the specified log group. You can list all the log streams or filter the results by prefix. You can also control how the results are ordered.
   * This operation has a limit of five transactions per second, after which transactions are throttled.
   * @param {object} query={}
   * @param {string} query.logGroupName - The name of the log group.
   * @param {boolean} [query.descending] - If the value is true, results are returned in descending order. If the value is to false, results are returned in ascending order. The default value is false.
   * @param {number} [query.limit] - The maximum number of items returned. If you don't specify a value, the default is up to 50 items.
   * @param {string} [query.logStreamNamePrefix] - If orderBy is LastEventTime, you cannot specify this parameter.
   * @param {string} [query.nextToken] - The token for the next set of items to return. (You received this token from a previous call.)
   * @param {string} [query.orderBy]- If the value is LogStreamName, the results are ordered by log stream name. If the value is LastEventTime, the results are ordered by the event time. The default value is LogStreamName. ...
   * @returns {Promise<string[]>}
   */
  async getLogStreams(query = {}) {
    try {
      await this.checkSessionExpiration();

      const command = new DescribeLogStreamsCommand(query);
      const { logStreams } = await this.client.send(command);

      return logStreams.map((stream) => stream.logStreamName);
    } catch (e) {
      throw new Error(`Some problems with log streams!\n ${e}`);
    }
  }

  /**
   * Lists log events from the specified log stream. You can list all of the log events or filter using a time range.
   * @param {object} query
   * @param {string} query.logGroupName - The name of the log group.
   * @param {string} query.logStreamName - The name of the log stream.
   * @param {number} [query.startTime] - The start of the time range, expressed as the number of milliseconds after Jan 1, 1970 00:00:00 UTC. Events with a timestamp equal to this time or later than this time are included. Events with a timestamp earlier than this time are not included.
   * @param {number} [query.endTime] - The end of the time range, expressed as the number of milliseconds after Jan 1, 1970 00:00:00 UTC. Events with a timestamp equal to or later than this time are not included.
   * @param {number} [query.limit] - The maximum number of log events returned. If you don't specify a value, the maximum is as many log events as can fit in a response size of 1 MB, up to 10,000 log events.
   * @param {string} [query.nextToken] - The token for the next set of items to return. (You received this token from a previous call.) Using this token works only when you specify true for startFromHead.
   * @param {boolean} [query.startFromHead] - If the value is true, the earliest log events are returned first. If the value is false, the latest log events are returned first. The default value is false. If you are using nextToken in this operation, you must specify true for startFromHead.
   * @returns {Promise<{events: {message: string, key: number, timestamp: |string}[]}>}
   */
  async getLogEvents(query = {}) {
    try {
      await this.checkSessionExpiration();

      const command = new GetLogEventsCommand({
        limit: this.DEFAULT_LOG_LIMIT,
        ...query,
      });
      const response = await this.client.send(command);

      return {
        ...response,
        events: response.events.map(this._transformEventObj).reverse(),
      };
    } catch (e) {
      throw new Error(`Some problems with log events!\n ${e}`);
    }
  }

  /**
   * Lists log events from the specified log group. You can list all the log events or filter the results using a filter pattern, a time range, and the name of the log stream.
   * @param {object} query
   * @param {string} query.logGroupName - The name of the log group to search.
   * @param {string[]} [query.logStreamNames] - Filters the results to only logs from the log streams in this list.
   * @param {string} [query.filterPattern] - The filter pattern to use. If not provided, all the events are matched.
   * @param {number} [query.startTime] - The start of the time range, expressed as the number of milliseconds after Jan 1, 1970 00:00:00 UTC. Events with a timestamp before this time are not returned. If you omit startTime and endTime the most recent log events are retrieved, to up 1 MB or 10,000 log events.
   * @param {number} [query.endTime] - The end of the time range, expressed as the number of milliseconds after Jan 1, 1970 00:00:00 UTC. Events with a timestamp later than this time are not returned.
   * @param {number} [query.limit] - The maximum number of events to return. The default is 10,000 events.
   * @param {string} [query.logStreamNamePrefix] - Filters the results to include only events from log streams that have names starting with this prefix.
   * @param {string} [query.nextToken] - The token for the next set of items to return. (You received this token from a previous call.) Using this token works only when you specify true for startFromHead.
   * @returns {Promise<{events: {message: string, key: number, timestamp: string}[]}>}
   */
  async filterLogEvents(query = {}) {
    try {
      await this.checkSessionExpiration();

      const command = new FilterLogEventsCommand({
        limit: this.DEFAULT_LOG_LIMIT,
        ...this._cleanUpFalsy(query),
      });
      const response = await this.client.send(command);

      return {
        ...response,
        events: response.events.map(this._transformEventObj).reverse(),
      };
    } catch (e) {
      throw new Error(`Some problems with events' filtering!\n ${e}`);
    }
  }

  /**
   * @returns {function<void>}
   */
  destroy() {
    return this.client?.destroy();
  }

  /**
   * @param {string} time
   * @private
   */
  set expirationTime(time) {
    this.sessionEndTimestamp = new Date(time).getTime();
  }

  /**
   * __Should be executed before every interaction with CloudWatchLogsClient instance!__
   * @returns {Promise<void> | void}
   * @private
   */
  async checkSessionExpiration() {
    const now = Date.now();

    if (this.sessionEndTimestamp < now) {
      await this.initClient();
    }
  }

  /**
   * This function gets temporary credentials and creates a CloudWatchLogsClient instance
   * @returns {Promise<void>}
   * @private
   */
  async initClient() {
    const { data: receivedCredentials } = await getInstance().get(CREDENTIAL_URL);
    const { accessKeyId, expiration, secretAccessKey, sessionToken } = receivedCredentials;
    this.expirationTime = expiration;

    this.client = new CloudWatchLogsClient({
      region: "eu-central-1",
      credentials: {
        accessKeyId,
        expiration,
        secretAccessKey,
        sessionToken,
      },
    });
  }

  /**
   * Utility function for transform.
   * @param {object} singleEvent - received event object
   * @param {string} singleEvent.message
   * @param {number} singleEvent.timestamp
   * @param {number} singleEvent.ingestionTime
   * @param {number} id
   * @returns {{message: string, key: number, timestamp: string}}
   * @private
   */
  _transformEventObj(singleEvent, id) {
    const { message, timestamp, ingestionTime } = singleEvent;
    return {
      key: ingestionTime * id,
      message,
      timestamp: formatDate(timestamp),
    };
  }

  /**
   * Utility function for transform.
   * @param {object} query
   * @returns {object}
   * @private
   */
  _cleanUpFalsy(query) {
    return Object.keys(query)
      .filter((key) => !!query[key])
      .reduce((acc, key) => ({ ...acc, [key]: query[key] }), {});
  }
}
