import React from "react";
import { Form, DatePicker } from "antd";
import { USER_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function UserDateOfBirth({ user, disabled }) {
  if (user.type === USER_TYPES_AS_OBJECT.natural_person) {
    return (
      <React.Fragment>
        <Form.Item name="personalInfo.dateOfBirth" label={"Date of birth"}>
          <DatePicker disabled={disabled} format="YYYY-MM-DD" />
        </Form.Item>
      </React.Fragment>
    );
  }
  return null;
}

export default UserDateOfBirth;
