/* eslint-disable */
import Cookies from "js-cookie";
import { getInstance } from "../utils/helpers";
import { mapAndPaginationToServerPagination } from "../utils/helpers";
import { BALANCE_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function getCountries() {
  const url = `/api/countries/list`;
  return getInstance().get(url);
}

function getUsers() {
  const url = "/api/admin/users/list?limit=100&sorder=desc";
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function toggleUser(id, disabled) {
  const url = `/api/admin/users/toggle/${id}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().patch(url, { disabled });
}

function deleteUser(id) {
  const url = `/api/admin/users/byid/${id}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.delete(url);
}

function getOwnerSettings() {
  const url = "/api/owner-settings";
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function postOwnerSettings(payload) {
  const url = "/api/owner-settings";
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().post(url, payload);
}

function getPurchasesList(type = "mint") {
  const url = `/api/purchases/list4manager?type=${type}&sorder=desc&limit=200`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function getWithdrawList() {
  const url = `/api/withdraw/list4manager?sorder=desc&limit=200`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function updateUserStatus(id, status) {
  const url = `/api/admin/users/${id}`;
  const payload = {
    status,
  };
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().put(url, payload);
}

function getTransactionsListFilterable(type, filter, pagination) {
  let url = `/api/purchases/list4manager`;

  if (type === BALANCE_TYPES_AS_OBJECT.withdraw) {
    url = `/api/withdraw/list4manager`;
  }

  const params = {
    type,
  };

  const pageParams = mapAndPaginationToServerPagination(pagination);

  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;

  Object.keys(filter).forEach((key) => {
    if (filter[key]) {
      params[key] = filter[key];
    }
  });

  const result = getInstance().get(url, { params: { ...params, ...pageParams } });

  return result;
}

export {
  getUsers,
  getPurchasesList,
  getTransactionsListFilterable,
  getOwnerSettings,
  postOwnerSettings,
  updateUserStatus,
  deleteUser,
  toggleUser,
  getWithdrawList,
  getCountries,
};
