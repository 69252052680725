import React from "react";
import { Typography } from "antd";

function CopyableLink({ url, value, label }) {
  return (
    <Typography.Text copyable={{ text: value }}>
      <Typography.Link target="_blank" href={url}>
        {label}
      </Typography.Link>
    </Typography.Text>
  );
}

function CopyableText({ value, label }) {
  return <Typography.Text copyable={{ text: value }}>{label}</Typography.Text>;
}

export { CopyableLink, CopyableText };
