import React from "react";
import { Alert, Divider } from "antd";
import PropTypes from "prop-types";
import KycDocumentCheckStatus from "../KycDocumentStatus";

function Message({ data, className, showStatus }) {
  return (
    <div title={data.description} className={className}>
      {data.description}
      <KycDocumentCheckStatus data={data} showStatus={showStatus} />
    </div>
  );
}

function Alerts({ header, data, fullText, onEdit, editable, showStatus }) {
  const className = fullText ? "" : "truncate";

  return (
    <div>
      <h4>{header}</h4>
      {data.map((row) => {
        return (
          <Alert
            type="warning"
            key={`kyc-required-action${row.id}`}
            message={
              <Message
                data={row}
                showStatus={showStatus}
                editable={editable}
                onEdit={(val) => onEdit(row, val)}
                className={className}
              />
            }
          />
        );
      })}
      <Divider />
    </div>
  );
}

function UserAlerts({ header, data, fullText = false, showStatus = false }) {
  return (
    <div>
      {data && data.length > 0 ? (
        <Alerts header={header} data={data} showStatus={showStatus} fullText={fullText} />
      ) : null}
    </div>
  );
}

UserAlerts.defaultProps = {
  fullText: false,
  showStatus: false,
};

UserAlerts.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array,
  fullText: PropTypes.bool,
  showStatus: PropTypes.bool,
};

export default UserAlerts;
