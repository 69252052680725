import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Row from "antd/lib/grid/row";
import Col from "antd/lib/grid/col";
import Cascader from "antd/lib/cascader";
import Search from "antd/lib/input/Search";
import DatePicker from "antd/lib/date-picker";
import AwsLogsTable from "../../components/AwsLogsTable";
import { useSuperState } from "../../hooks";
import AwsCloudWatchService from "../../services/awsCloudWatch";
import ReloadButton from "../../components/ReloadButton";

const { RangePicker } = DatePicker;

const SystemMonitoringPage = () => {
  const awsLogsService = useMemo(() => new AwsCloudWatchService(), []);

  const { push: pushToHistory } = useHistory();
  const { logGroup, logStream } = useParams();

  const [logEventsObj, setLogEventsObj] = useSuperState({}, true);
  const [cascadeLayer, setCascadeLayer] = useSuperState([], true);
  const [loading, setLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [rangeValues, setRangeValues] = useState([0, 0]);

  const updateLogEvents = useCallback(
    async ({ logGroupName, logStreamName }) => {
      const response = await awsLogsService.getLogEvents({ logGroupName, logStreamName });

      setLogEventsObj(response);
    },
    [awsLogsService, setLogEventsObj]
  );

  const changeCascade = useCallback(
    (value) => {
      const [logGroupName, logStreamName] = value || [];
      setLoading(true);

      switch (value.length) {
        case 2:
          updateLogEvents({ logGroupName, logStreamName })
            .then(() => pushToHistory(`/main/system-monitoring/${logGroupName}/${logStreamName}`))
            .catch((error) => console.error("system-monitoring", error))
            .finally(() => setLoading(false));
          break;
        case 1:
          pushToHistory(`/main/system-monitoring/${logGroupName}`);
          setLogEventsObj({});
          setLoading(false);
          break;
        default:
          pushToHistory(`/main/system-monitoring`);
          setLogEventsObj({});
          setLoading(false);
          break;
      }
    },
    [pushToHistory, setLogEventsObj, updateLogEvents]
  );

  useEffect(() => {
    (async () => {
      try {
        const logGroupNameList = await awsLogsService.getLogGroups();
        setCascadeLayer(
          logGroupNameList.map((groupName) => ({
            value: groupName,
            label: groupName,
            isLeaf: false,
          }))
        );

        if (logGroup && logStream) {
          changeCascade([logGroup, logStream]);
        }
      } catch (err) {
        console.error(err);
      }
    })();

    return () => awsLogsService.destroy();
  }, [awsLogsService, setCascadeLayer, logGroup, logStream, changeCascade]);

  const updateLogStreams = async (logGroupName) => {
    return await awsLogsService.getLogStreams({ logGroupName });
  };

  const filterLogEvents = async ({ groupName, streamName, filter, startTime, endTime }) => {
    const response = await awsLogsService.filterLogEvents({
      filterPattern: filter,
      logGroupName: groupName,
      logStreamNames: [streamName],
      startTime: startTime,
      endTime: endTime,
    });

    setLogEventsObj(response);
  };

  const loadData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    updateLogStreams(targetOption.value)
      .then((logStreamNameList) => {
        targetOption.loading = false;
        targetOption.children = logStreamNameList.map((item) => ({
          label: item,
          value: item,
        }));
        setCascadeLayer([...cascadeLayer]);
      })
      .catch(console.error);
  };

  const onSearch = (newSearchValue) => {
    if (newSearchValue !== searchValue) {
      setSearchValue(newSearchValue);
      setLoading(true);
      filterLogEvents({
        groupName: logGroup,
        streamName: logStream,
        filter: newSearchValue,
        startTime: rangeValues[0],
        endTime: rangeValues[1],
      })
        .catch((error) => console.error("system-monitoring", error))
        .finally(() => setLoading(false));
    }
  };

  const searchByRange = (range) => {
    const [start = 0, end = 0] = range || [];
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();

    if (compareRangeWithPrevious([startTime, endTime])) {
      setRangeValues([startTime, endTime]);
      setLoading(true);
      filterLogEvents({
        groupName: logGroup,
        streamName: logStream,
        startTime,
        endTime,
        filter: searchValue,
      })
        .catch((error) => console.error("system-monitoring", error))
        .finally(() => setLoading(false));
    }
  };

  const compareRangeWithPrevious = (newRange) => {
    return newRange.some((item, index) => item !== rangeValues[index]);
  };

  const tableProps = {
    logEvents: logEventsObj.events || [],
    loading,
  };

  const reloadData = () => {
    setLoading(true);
    filterLogEvents({
      groupName: logGroup,
      streamName: logStream,
      startTime: rangeValues[0],
      endTime: rangeValues[1],
      filter: searchValue,
    })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Row key={"search-panel"} style={{ margin: ".5rem" }} justify={"space-between"}>
        <label>
          Please select a log group:{"  "}
          <Cascader
            options={cascadeLayer}
            loadData={loadData}
            onChange={changeCascade}
            placeholder={"Log Group"}
            changeOnSelect
            style={{ width: 200 }}
          />
        </label>
        {logGroup && logStream ? (
          <>
            <Search
              allowClear
              enterButton
              onSearch={onSearch}
              placeholder={"Filter Events"}
              style={{ width: "30%", margin: "0 1rem" }}
            />
            <RangePicker
              format={"DD/MM/YYYY, HH:mm:ss"}
              onChange={searchByRange}
              renderExtraFooter={() => "Please select a range..."}
              showTime
              style={{ margin: "0 1rem" }}
            />
          </>
        ) : null}
        {logGroup && logStream ? <ReloadButton loading={loading} onClick={reloadData} /> : null}
      </Row>
      <Row key={"logs-table"}>
        <Col>
          <AwsLogsTable {...tableProps} />
        </Col>
      </Row>
    </>
  );
};
export default SystemMonitoringPage;
