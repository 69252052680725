/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import List from "antd/lib/list";
import Badge from "antd/lib/badge";
import Typography from "antd/lib/typography";
import NotificationOutlined from "@ant-design/icons/lib/icons/NotificationOutlined";
import Drawer from "antd/lib/drawer";
import { formatDate } from "eurst-shared/src/helpers/date";
import "./index.css";

function Notifications({ hasNew, data, count, page, pageSize, onClick, onChangePage }) {
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(page);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setCurrentPage(1);
  };
  // List with pagination - https://ant.design/components/list/#API -> pagination

  return (
    <div style={{ padding: 10 }} className={"align-right"}>
      <a href="#" onClick={showDrawer} className={"notifications"}>
        <Badge count={hasNew} size="small">
          <NotificationOutlined />
        </Badge>
      </a>
      <Drawer
        width={400}
        title="Notifications"
        placement="right"
        closable
        destroyOnClose={true}
        onClose={onClose}
        visible={visible}>
        <List
          bordered
          dataSource={data}
          pagination={{
            onChange: (page) => {
              onChangePage({ page });
              setCurrentPage(page);
            },
            showSizeChanger: false,
            total: count,
            current: currentPage,
            pageSize,
            responsive: true,
            showLessItems: true,
          }}
          renderItem={(item) => {
            return (
              <List.Item
                onClick={() => (item.unread ? onClick(item) : "")}
                style={item.unread ? { cursor: "pointer" } : {}}>
                <List.Item.Meta title={formatDate(item.createdAt)} />

                {item.unread ? <Typography.Text mark>[New]</Typography.Text> : ""}
                {item.text}
              </List.Item>
            );
          }}
        />
      </Drawer>
    </div>
  );
}

Notifications.propTypes = {
  pageSize: PropTypes.number,
  hasNew: PropTypes.any,
  data: PropTypes.array,
  count: PropTypes.number,
  onClick: PropTypes.func,
  onChangePage: PropTypes.func,
};

export default Notifications;
