/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, Redirect, Switch, useLocation } from "react-router-dom";

import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
import Divider from "antd/lib/divider";

import DesktopOutlined from "@ant-design/icons/DesktopOutlined";
import LineChartOutlined from "@ant-design/icons/lib/icons/LineChartOutlined";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";

import DashboardPage from "../DashboardPage";
import UserDetailsPage from "../DashboardPage/details";
import LogoutPage from "../LogoutPage";
import SettingsPage from "../SettingsPage";
import SystemMonitoringPage from "../SystemMonitoringPage";
import TransactionsPage from "../TransactionsPage";

import Notifications from "../../components/Notifications";
import PrivateRoute from "../../components/PrivateRoute";
import MinterAlarm from "../../components/MinterAlarm";
import { getNotifications, markRead } from "../../services/notifications";
import { getMinter } from "../../services/eurst";
import { useSetInterval } from "../../hooks";

const network = process.env.REACT_APP_NETWORK;
const { Sider } = Layout;

function MainTemplate({ socket }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState("users");
  const [notifications, setNotifications] = useState([]);
  const [notificationsHasNew, setNotificationsHasNew] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const initialMinter = {
    balance: 0,
    unit: "ether",
  };
  const [minter, setMinter] = useState(initialMinter);

  const [page, setPage] = useState(1);

  const fetchNotifications = async (val = {}) => {
    const data = await getNotifications(val.page, 10);

    setPage(val.page);
    setNotifications(data.data);
    setNotificationsCount(data.count);
    setNotificationsHasNew(data.hasNew);
  };

  const fetchMinter = async () => {
    const data = await getMinter();

    setMinter(data.data);
  };

  useEffect(() => {
    setCurrent(location.pathname.split("/")[2]);
    fetchNotifications(page);
    fetchMinter();
  }, []);

  useSetInterval(fetchMinter, 600000, {});

  useSetInterval(
    () => {
      fetchNotifications({ page, notificationsHasNew }).catch(console.error);
    },
    600000,
    { page, notificationsHasNew }
  );

  const onCollapse = (value) => {
    setCollapsed(value);
  };

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  // implement me - serverside
  const handleUnreadMessages = async (notification) => {
    await markRead(notification.id);

    await fetchNotifications({ page, notificationsHasNew });
  };

  return (
    <React.Fragment>
      <Layout>
        <Layout.Header>
          <MinterAlarm network={network} minter={minter} />

          <Notifications
            onChangePage={fetchNotifications}
            count={notificationsCount}
            pageSize={10}
            page={page}
            hasNew={notificationsHasNew}
            data={notifications}
            onClick={handleUnreadMessages}
          />
        </Layout.Header>
        <Layout className="site-layout" style={{ width: "100%" }}>
          <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div className="logo" />

            <Menu
              theme="dark"
              onClick={handleClick}
              selectedKeys={[current]}
              defaultSelectedKeys={["1"]}
              mode="inline"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 48px)",
              }}
              onSelect={({ item }) => console.log("item")}>
              <Menu.Item key="users" icon={<UserOutlined />}>
                <Link to={"/main/users"}>Users</Link>
              </Menu.Item>
              <Menu.Item key="transactions" icon={<DesktopOutlined />}>
                <Link to={"/main/transactions"}>Transactions</Link>
              </Menu.Item>
              <Menu.Item key="monitoring" icon={<LineChartOutlined />}>
                <Link to={"/main/system-monitoring"}>System Monitoring</Link>
              </Menu.Item>

              <Menu.Item key="settings" icon={<SettingOutlined />}>
                <Link to={"/main/settings"}>Settings</Link>
              </Menu.Item>

              <Menu.Item key={5}>
                <Divider />
              </Menu.Item>

              <Menu.Item
                icon={<LogoutOutlined />}
                key={6}
                style={{
                  marginTop: "auto",
                }}>
                <Link to={"/main/logout"}>Logout</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout.Content>
            <Switch>
              <PrivateRoute exact path="/main/users" component={DashboardPage} />

              <PrivateRoute exact path="/main/users/:id" component={UserDetailsPage} />

              <PrivateRoute exact path="/main/transactions" component={TransactionsPage} />
              <PrivateRoute exact path="/main/settings" component={SettingsPage} />
              <PrivateRoute
                exact
                path="/main/system-monitoring/:logGroup?/:logStream?"
                component={SystemMonitoringPage}
              />
              <PrivateRoute exact path="/main/logout" component={LogoutPage} />
              <PrivateRoute path="*" component={() => <Redirect to="/main/users" />} />
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout>
    </React.Fragment>
  );
}

export default MainTemplate;
