import React from "react";
import { InputNumber, Form } from "antd";

const MinMaxFormField = ({ label, onChange, nameMin, nameMax }) => {
  return (
    <React.Fragment>
      <div style={{ width: "100%" }} className="ant-col ant-form-item-label">
        <label title={label}>{label}</label>
      </div>

      <Form.Item noStyle name={nameMin}>
        <InputNumber
          style={{ width: "50%" }}
          onChange={(value) => {
            onChange(nameMin, value);
          }}
        />
      </Form.Item>
      <Form.Item noStyle name={nameMax}>
        <InputNumber style={{ width: "50%" }} onChange={(value) => onChange(nameMax, value)} />
      </Form.Item>
    </React.Fragment>
  );
};

export default MinMaxFormField;
