import React, { useState } from "react";
import { Drawer, Button, DatePicker, Form, Input, Select, Badge } from "antd";
import { FORMAT_FOR_DATE_WITH_TIME } from "../../constants/constants";
import { WITHDRAW_STATUSES } from "eurst-shared/src/enums";
import MinMaxFormField from "../../components/MinMaxFormField";
import { getFilledInObject } from "../../utils/helpers";

const { RangePicker } = DatePicker;
const { Option } = Select;

function WithdrawFilter({ onSubmit, onReset, loading }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);

  const filtersFilled = () => {
    const values = form.getFieldsValue();

    const count = getFilledInObject(values);

    setCount(count);
  };

  const onChange = (name, value) => {
    form.setFieldsValue({ [name]: value });
  };

  const onFiltersReady = (values) => {
    let created = {};
    if (values.createdAt && values.createdAt.length > 0) {
      created.createdAtFrom = values.createdAt[0].toISOString();
      created.createdAtTo = values.createdAt[1].toISOString();
    }
    onSubmit({
      ...values,
      ...created,
    });

    filtersFilled();

    onClose();
  };

  const onClearFilter = () => {
    form.resetFields();

    onReset({});

    filtersFilled();

    onClose();
  };

  const onClose = () => {
    setVisible(false);
  };

  if (!visible) {
    return (
      <React.Fragment>
        <Badge count={count}>
          <Button type="primary" onClick={() => setVisible(true)}>
            Filter
          </Button>
        </Badge>
        <Button loading={loading} onClick={onClearFilter}>
          Reset
        </Button>
      </React.Fragment>
    );
  }
  return (
    <Drawer visible={visible} title="Search transactions" width={400} onClose={onClose}>
      <Form
        form={form}
        name="filter"
        style={{ height: "100%", textAlign: "left" }}
        layout={"vertical"}
        onFinish={onFiltersReady}
        initialValues={{}}
        scrollToFirstError>
        <Form.Item name="createdAt" label="Date">
          <RangePicker
            inputReadOnly
            format={FORMAT_FOR_DATE_WITH_TIME}
            style={{ width: 250, marginBottom: 8 }}
          />
        </Form.Item>
        <Form.Item label="User email" name="userEmail">
          <Input />
        </Form.Item>

        <MinMaxFormField
          nameMin="amountFrom"
          label="Amount from - to"
          nameMax="amountTo"
          onChange={onChange}
        />

        <Form.Item label="Status" name="status">
          <Select onChange={(value) => onChange("status", value)}>
            {WITHDRAW_STATUSES.map((status) => {
              return (
                <Option key={status.id} label={status.label} value={status.id}>
                  {status.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Button type="primary" loading={loading} htmlType="submit">
          Search
        </Button>
        <Button loading={loading} onClick={onClearFilter}>
          Reset
        </Button>
      </Form>
    </Drawer>
  );
}

export default WithdrawFilter;
