import React from "react";
import { Form, Select } from "antd";
import { USER_TYPES_AS_OBJECT } from "eurst-shared/src/enums";
import { PT_COUNTRIES } from "eurst-shared/src/helpers/countries";

const { Option } = Select;

function UserCitizenship({ user, disabled }) {
  if (user.type === USER_TYPES_AS_OBJECT.natural_person) {
    return (
      <React.Fragment>
        <Form.Item name="personalInfo.citizenship" label={"Citizenship"}>
          <Select allowClear disabled={disabled} value={user.personalInfo?.citizenship}>
            {PT_COUNTRIES.map((country) => {
              return (
                <Option key={`citizenship${country.id}`} value={country.id}>
                  {country.attributes.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  }
  return null;
}

export default UserCitizenship;
