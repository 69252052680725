import Cookies from "js-cookie";
import { getInstance } from "../utils/helpers";

async function getNotifications(page = 1, limit = 10) {
  const url = `/api/notifications/list?page=${page}&limit=${limit}&sorder=desc`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  const result = await getInstance().get(url);

  return result;
}

function markRead(id) {
  const url = `/api/notifications/${id}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  const result = getInstance()
    .patch(url)
    .then((response) => {
      console.info("response", response);
      return response;
    });
  return result;
}

export { getNotifications, markRead };
