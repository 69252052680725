import React from "react";
import { Divider, Tag } from "antd";
import PropTypes from "prop-types";
import { KYC_DOCUMENT_CHECK_STATUSES } from "eurst-shared/src/enums";

function KycDocumentCheckStatus({ data, showStatus }) {
  const status = KYC_DOCUMENT_CHECK_STATUSES.find((r) => r.id === data.status);
  if (!showStatus) return null;

  return (
    <React.Fragment>
      <Divider />
      <Tag color={status.color}>{status.label}</Tag>
    </React.Fragment>
  );
}

KycDocumentCheckStatus.defaultProps = {
  showStatus: false,
};

KycDocumentCheckStatus.propTypes = {
  data: PropTypes.array,
  showStatus: PropTypes.bool,
};

export default KycDocumentCheckStatus;
