import React from "react";
import { USER_TYPES } from "eurst-shared/src/enums";

function UserType({ user }) {
  const type = USER_TYPES.find((r) => r.id === user.type);

  return type ? <span>{type.label}</span> : null;
}

export default UserType;
