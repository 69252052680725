const STATUSES_COLOR = {
  success: "green",
  waiting: "yellow",
  failed: "red",
  reject: "red",
};

const FORMAT_FOR_DATE_WITH_TIME = "DD/MM/YYYY HH:mm:ss";
const FORMAT_FOR_DATE = "DD/MM/YYYY";

export { STATUSES_COLOR, FORMAT_FOR_DATE, FORMAT_FOR_DATE_WITH_TIME };
