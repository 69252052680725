import axios from "axios";
import { get } from "lodash";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import auth from "../services/auth";

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("token")}`,
  },
});

instance.interceptors.response.use(
  function (response) {
    // if (get(response, "data.data")) {
    // 	return response.data.data
    // }
    if (response.data.data) {
      return response.data;
    }
    return response;
  },
  function (error) {
    let response = get(error, "response");
    if (!response) {
      response = error.toJSON();
      response.data = { message: error.message };
    }

    if (response.status === 422) {
      // PrimeTrust errors
      const errorMessages = getPTErrors(response);
      const message = errorMessages.join("<br/>");
      toast(message, {
        type: "error",
      });
      return Promise.reject(response);
    }

    toast(response.data.message, {
      type: "error",
    });

    const isAuthError = response.status === 401 && response.data.name === "token-expired";

    const isPermissionError =
      response.status === 403 && ["no-permissions", "user-disabled"].includes(response.data.code);

    const noSuchUser = response.status === 404 && response.data.name === "no-such-user";
    if (isAuthError || isPermissionError || noSuchUser) {
      auth.logout();

      setTimeout(() => {
        if (location.pathname !== "/") location.assign("/");
      }, 500);
    }

    /*eslint no-undef: 0*/
    return Promise.reject(response);
  }
);

axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [apiUrl];
    const token = Cookies.get("token");
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    /*eslint no-undef: 0*/
    return Promise.reject(error);
  }
);
/** Returns the axios instance */
export function getInstance() {
  return instance;
}

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const mapAndPaginationToServerPagination = (params = {}) => {
  const result = {};

  // switch (params.sortOrder) {
  //   case 'ascend':
  //     result.sorder = 'asc';
  //     break;
  //   case 'descend':
  //     result.sorder = 'desc';
  //     break;
  //   default:
  //     break;
  // }
  // switch (params.sortOrder) {
  //   case 'ascend':
  //     result.sorder = 'asc';
  //     break;
  //   case 'descend':
  //     result.sorder = 'desc';
  //     break;
  //   default:
  //     break;
  // }

  result.limit = params.pageSize;
  result.page = params.current;
  result.sort = params.sortField;

  return result;
};

function getFilledInObject(values) {
  let count = Object.keys(values).filter((key) => {
    return values[key] ? true : false;
  });

  return count.length;
}

function getPTErrors(serverResponse) {
  if (!serverResponse.data.details) return;

  const errors = serverResponse.data.details.map((err) => {
    const string = `${err.title} ${err.source.pointerCamelCase} ${err.detail}`;
    return string;
  });

  return errors;
}

export { capitalize, mapAndPaginationToServerPagination, getFilledInObject, getPTErrors };
