import React from "react";
import { Layout, Tag, Tabs, Typography } from "antd";
import { FORMAT_FOR_DATE_WITH_TIME, STATUSES_COLOR } from "../../constants/constants";
import {
  BALANCE_STATUSES,
  BALANCE_TYPES_AS_OBJECT,
  WITHDRAW_STATUSES_AS_OBJECT_WITH_PROPERTY,
  PURCHASE_SOURCES_AS_OBJECT_WITH_PROPERTY,
} from "eurst-shared/src/enums";
import moment from "moment";

// url={etherscanUrl(network, "address", value)}
import { etherscanUrl } from "eurst-shared/src/helpers/urls";
import { shortener } from "eurst-shared/src/helpers/string";
import { STATUSES_COLOR_FOR_WITHDRAW } from "eurst-frontend/src/constants/constants";
import { useFragmentState } from "../../hooks";
import TransactionsList from "./transactions";

const { Content } = Layout;
const { Text, Link } = Typography;
const { TabPane } = Tabs;

const network = process.env.REACT_APP_NETWORK;

const columns = {
  purchases: [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return moment(text).format(FORMAT_FOR_DATE_WITH_TIME);
      },
    },
    {
      title: "User email",
      dataIndex: "user",
      key: "user",
      /* eslint-disable react/display-name */
      render: (text, record) => {
        //return record.user.email;
        return <Text copyable={{ text: record.user.email }}>{record.user.email}</Text>;
      },
      /* eslint-enable react/display-name */
    },
    {
      title: "EURST amount",
      dataIndex: "eurAmount",
      key: "eurAmount",
    },
    {
      title: "FX rate  at the time of transaction",
      dataIndex: "currencyRate",
      key: "currencyRate",
    },
    {
      title: "USD payment amount",
      dataIndex: "usd",
      key: "usd",
      render: (text, record) => {
        const result = record.totalAmount;

        return result;
      },
    },
    {
      title: "Payment method",
      dataIndex: "source",
      key: "source",
      render: function renderSource(source) {
        return (
          <React.Fragment>
            <span>{PURCHASE_SOURCES_AS_OBJECT_WITH_PROPERTY[source].label}</span>
          </React.Fragment>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: function renderStatus(status, record) {
        let color = STATUSES_COLOR[status];
        return (
          <React.Fragment>
            <Tag color={color} key={status}>
              {BALANCE_STATUSES[status].label}
            </Tag>
            <div>{record.message ? record.message : null}</div>
          </React.Fragment>
        );
      },
    },
    {
      title: "Reference",
      dataIndex: "trnHash",
      key: "trnHash",
      render: function renderTrnHash(trnHash) {
        if (!trnHash) return;
        return (
          <React.Fragment>
            <Text copyable={{ text: trnHash }}>
              <Link target="_blank" href={etherscanUrl(network, "tx", trnHash)}>
                {shortener(trnHash)}
              </Link>
            </Text>
          </React.Fragment>
        );
      },
    },
    {
      title: "Fee (USD)",
      dataIndex: "fee",
      key: "fee",
      render: (value) => {
        const result = Math.abs(parseFloat(value));
        return result;
      },
    },
  ],
  withdraw: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return moment(text).format(FORMAT_FOR_DATE_WITH_TIME);
      },
    },
    {
      title: "User email",
      dataIndex: "user",
      key: "user",
      render: (value, record) => {
        return record.user.email;
      },
    },
    {
      title: "USD payment amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: function renderStatus(status) {
        let color = STATUSES_COLOR_FOR_WITHDRAW[status];
        return (
          <React.Fragment>
            <Tag color={color} key={status}>
              {WITHDRAW_STATUSES_AS_OBJECT_WITH_PROPERTY[status].label}
            </Tag>
          </React.Fragment>
        );
      },
    },
  ],
};

function TransactionsPage() {
  const [tableContentType, setTableContentType] = useFragmentState("mint");

  const handleTabsChange = async (key) => {
    setTableContentType(key);
  };

  return (
    <Content>
      <Tabs onChange={handleTabsChange} type="card" activeKey={tableContentType}>
        <TabPane tab="Minting" key={BALANCE_TYPES_AS_OBJECT.mint}>
          <TransactionsList
            tableContentType={BALANCE_TYPES_AS_OBJECT.mint}
            columns={columns.purchases}
          />
        </TabPane>
        <TabPane tab="Redeem" key={BALANCE_TYPES_AS_OBJECT.redeem}>
          <TransactionsList
            tableContentType={BALANCE_TYPES_AS_OBJECT.redeem}
            columns={columns.purchases}
          />
        </TabPane>

        <TabPane tab="Withdraw" key={BALANCE_TYPES_AS_OBJECT.withdraw}>
          <TransactionsList
            tableContentType={BALANCE_TYPES_AS_OBJECT.withdraw}
            columns={columns.withdraw}
          />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default TransactionsPage;
