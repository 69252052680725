const events = {};

const EventEmitter = {
  dispatch: (event, data) => {
    // console.info('dispatch', event, events[event])
    if (!events[event]) return;
    events[event].forEach((callback) => callback(data));
  },
  subscribe: (event, callback) => {
    if (!events[event]) events[event] = [];
    events[event].push(callback);

    return events[event].length - 1;
  },
  unsubscribe: (event, id) => {
    if (!events[event]) return;
    if (!events[event][id]) return;

    events[event] = events[event].filter((v, i) => i !== id);
  },
};

export default EventEmitter;
