import React from "react";
import { Layout } from "antd";
import TableUsersList from "./table-list";

function DashboardPage() {
  return (
    <Layout.Content style={{ width: "100%" }}>
      <TableUsersList />
    </Layout.Content>
  );
}

export default DashboardPage;
