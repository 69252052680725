import { useEffect, useRef } from "react";

/**
 * setInterval declarative hook
 * @param {func} callback
 * @param {integer} delay for setInterval
 * @param {object} some value to pass to setInterval worker
 * @returns {[*, function(*): void]}
 * @example
 *  ```
 * const [page, setPage] = useState(1)

   const fetchNotifications = async (page, limit) => {...call server api }

   useSetInterval(() => {
      fetchNotifications(page).catch(console.error);
    }, 10000, { page })
 *  ```
 */

export default function useSetInterval(callback, delay, value = {}) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current(value);
    }
    if (delay !== null) {
      let id = setInterval(tick, delay, value);
      return () => clearInterval(id);
    }
  }, [delay, value]);
}
