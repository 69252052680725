import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/auth";
import PropTypes from "prop-types";

function LogoutPage({ socket }) {
  useEffect(() => {
    auth.logout(socket);
  }, [socket]);

  return (
    <>
      <Redirect to="/" />
    </>
  );
}

LogoutPage.propTypes = {
  socket: PropTypes.any,
};

export default LogoutPage;
