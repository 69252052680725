/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";

function ReloadButton({ onClick, loading }) {
  return (
    <span style={{ float: "right" }}>
      <Button loading={loading} onClick={onClick} title={"Reload data"}>
        <ReloadOutlined />
      </Button>
    </span>
  );
}

ReloadButton.propTypes = {
  onClick: PropTypes.func,
};

export default ReloadButton;
