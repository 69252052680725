import React, { useEffect, useState } from "react";
import { Table, Row } from "antd";
import { getTransactionsListFilterable } from "../../services/admin";
import ReloadButton from "../../components/ReloadButton";
import PurchaseFilter from "./purchase-filter";
import WithdrawFilter from "./withdraw-filter";

import { BALANCE_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function TransactionsList({ tableContentType, columns }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialFilter = {
    type: tableContentType,
  };

  const [filter, setFilter] = useState(initialFilter);

  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["topRight", "bottomRight"],
    //hideOnSinglePage: true,
    onChange: (page) => {
      pagination.current = page;

      setPagination(pagination);
    },
    onShowSizeChange: (current, size) => {
      pagination.pageSize = size;

      setPagination(pagination);
    },
  };
  const [pagination, setPagination] = useState(initialPagination);

  const getFilter = (type) => {
    let result;
    switch (type) {
      case BALANCE_TYPES_AS_OBJECT.withdraw:
        result = (
          <WithdrawFilter
            onSubmit={handleTableChange}
            onReset={handleTableChange}
            loading={loading}
          />
        );
        break;
      default:
        result = (
          <PurchaseFilter
            onSubmit={handleTableChange}
            onReset={handleTableChange}
            loading={loading}
          />
        );
        break;
    }

    return result;
  };

  const fetchData = async (filters, pagination) => {
    setLoading(true);
    try {
      const response = await getTransactionsListFilterable(tableContentType, filters, pagination);
      setData(response.data);

      pagination.total = response.count;

      setPagination(pagination);
    } catch (err) {
      console.error("ERRR", err);
    } finally {
      setLoading(false);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    fetchData(initialFilter, pagination);
  }, []);
  /* eslint-enable */

  const handleTableChange = (updatedFilter) => {
    if (updatedFilter && !updatedFilter.current) {
      // can be if filter data - then click to Page - can be pagination object
      setFilter(updatedFilter);

      pagination.current = 1;

      return fetchData(updatedFilter, pagination);
    }

    return fetchData(filter, pagination);
  };

  return (
    <React.Fragment>
      <Row style={{ float: "right" }}>
        {getFilter(tableContentType)}

        <ReloadButton disabled={loading} onClick={() => handleTableChange(filter)} />
      </Row>
      <Table
        key={tableContentType}
        rowKey={(record) => record.id}
        pagination={pagination}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        columns={columns}
      />
    </React.Fragment>
  );
}
export default TransactionsList;
