import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import { PT_COUNTRIES, COUNTRIES_AS_OBJECT } from "eurst-shared/src/helpers/countries";
import { US_STATES } from "eurst-shared/src/enums";

const { Option } = Select;

const filterOption = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const TaxState = ({ visible, disabled }) => {
  if (!visible) return null;

  return (
    <Form.Item name={"personalInfo.taxState"}>
      <Select style={{ width: 100 }} showSearch filterOption={filterOption} disabled={disabled}>
        {US_STATES.map((state) => {
          return (
            <Option key={`state${state.code}`} value={state.code}>
              {state.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

function UserAddress({ form, user, disabled }) {
  const [showTaxState, setShowTaxState] = useState(
    user.personalInfo && user.personalInfo.countryOfResidence === COUNTRIES_AS_OBJECT.US
  );
  if (!user.personalInfo) {
    return <Form.Item label="Address">n/a</Form.Item>;
  }

  if (
    (user.personalInfo && !user.personalInfo.address) ||
    Object.keys(user.personalInfo.address).length === 0
  ) {
    return <Form.Item label="Address">n/a</Form.Item>;
  }

  const onChange = (value) => {
    if (value === COUNTRIES_AS_OBJECT.US) {
      setShowTaxState(true);
      return;
    }
    setShowTaxState(false);
  };

  return (
    <React.Fragment>
      <Form.Item label="Address">
        <Input.Group compact>
          <Form.Item
            name={["personalInfo.countryOfResidence"]}
            label={"Country"}
            rules={[{ required: true, message: "Country is required" }]}>
            <Select
              allowClear
              filterOption={filterOption}
              style={{ width: "100%" }}
              showSearch
              placeholder="Select country of residence"
              onChange={onChange}
              disabled={disabled}>
              {PT_COUNTRIES.map((country) => {
                return (
                  <Option key={`country${country.id}`} value={country.id}>
                    {country.attributes.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <TaxState visible={showTaxState} form={form} user={user} disabled={disabled} />

          <Form.Item name={["personalInfo.address.region"]} label={"Region"}>
            <Input disabled={disabled} placeholder={"Region"} help={"Region name"} />
          </Form.Item>

          <Form.Item
            name={["personalInfo.address.address1"]}
            label={"Street"}
            rules={[{ required: true, message: "Street is required" }]}>
            <Input disabled={disabled} placeholder={"Street"} help={"Street"} />
          </Form.Item>

          <Form.Item name={["personalInfo.address.zip"]} label={"Postal Code"}>
            <Input disabled={disabled} placeholder={"Postal code"} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </React.Fragment>
  );
}

export default UserAddress;
