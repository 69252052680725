import React from "react";
import { USER_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function UserEditorLabel({ user }) {
  if (user.type === USER_TYPES_AS_OBJECT.natural_person) {
    return <React.Fragment>KYC Information</React.Fragment>;
  }
  return <React.Fragment>KYB Information</React.Fragment>;
}

export default UserEditorLabel;
