import React, { useState } from "react";
import { Alert, Divider, Input, Button, Popconfirm } from "antd";
import PropTypes from "prop-types";
import FormOutlined from "@ant-design/icons/FormOutlined";
import { toast } from "react-toastify";
import KycDocumentCheckStatus from "../KycDocumentStatus";

const { TextArea } = Input;

function AlertDescription({ record, showStatus }) {
  return (
    <div>
      <KycDocumentCheckStatus data={record} showStatus={showStatus} />
    </div>
  );
}

function Message({ data, onEdit, editorVisible, toggleEdit }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = (evt) => {
    setValue(evt.target.value);
  };

  const onSave = () => {
    setLoading(true);

    setValue(value);

    onEdit(data, value)
      .then(() => {
        toggleEdit();

        toast("Saved.Note sent to user by email", {
          type: "success",
        });
      })
      // .catch(err => {
      //
      // })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancel = toggleEdit;

  return (
    <div title={data.description}>
      {editorVisible ? (
        <React.Fragment>
          <TextArea onChange={onChange} value={value ? value : data.description} />
          <Popconfirm
            title="Are you sure to save changes?. User will be notified by email"
            onConfirm={onSave}
            onCancel={cancel}
            okText="Yes"
            cancelText="No">
            <Button type="primary" loading={loading} disabled={!value}>
              Save
            </Button>
          </Popconfirm>
          <Button disabled={loading} onClick={() => toggleEdit()}>
            Cancel
          </Button>
        </React.Fragment>
      ) : value ? (
        value
      ) : (
        data.description
      )}
    </div>
  );
}

function Alerts({ header, data, onEdit, showStatus }) {
  const [visible, setVisible] = useState(false);

  const toggleEdit = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <h4>{header}</h4>
      {data.map((row) => {
        return (
          <Alert
            type="warning"
            action={<FormOutlined title="Edit" onClick={toggleEdit} />}
            key={`kyc-required-action${row.id}`}
            description={<AlertDescription showStatus={showStatus} record={row} />}
            message={
              <Message editorVisible={visible} data={row} toggleEdit={toggleEdit} onEdit={onEdit} />
            }
          />
        );
      })}
      <Divider />
    </div>
  );
}

function UserEditableAlerts({ header, data, onEdit, showStatus }) {
  return (
    <div>
      {data && data.length > 0 ? (
        <Alerts header={header} showStatus={showStatus} data={data} onEdit={onEdit} />
      ) : null}
    </div>
  );
}

UserEditableAlerts.defaultProps = {
  loading: false,
  onEdit: (evt) => {
    console.info("UserEditableAlerts", evt);
  },
};

UserEditableAlerts.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array,
  onEdit: PropTypes.func,
  showStatus: PropTypes.bool,
};

export default UserEditableAlerts;
