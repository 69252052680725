/* eslint-disable */
import Cookies from "js-cookie";
import { getInstance } from "../utils/helpers";

function updateKycDocumentText(id, payload) {
  const url = `/api/admin/kyc-document-checks/${id}`;

  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.patch(url, payload);
}

export { updateKycDocumentText };
