import React from "react";
import { Form, Select } from "antd";
import { USER_TYPES_AS_OBJECT } from "eurst-shared/src/enums";
import { GENDER } from "eurst-frontend/src/constants/constants";

const { Option } = Select;

function UserGender({ user, disabled }) {
  if (user.type === USER_TYPES_AS_OBJECT.natural_person) {
    return (
      <React.Fragment>
        <Form.Item label="Gender" name={"personalInfo.gender"} hasFeedback>
          <Select disabled={disabled}>
            {Object.keys(GENDER).map((key) => {
              return (
                <Option key={`gender${key}`} value={key}>
                  {key}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  }
  return null;
}

export default UserGender;
